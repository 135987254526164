import { LogSeverity } from '@app/shared/models/local/LogSeverity.enum';
import { MbBaseOAuthConfig, MbOAuthBrowserCacheLocation } from '@montblancsimpl/authentication';

export const environment = {
  production: false,
  umcQATesterRole: 'QA',
  umcApplicationKey: 'WelcomePackage',
  auth: true,
  linkBaseUrl: 'https://welcomepage.sta.montblanc.biz',
  apiUrl: 'https://welcomepage-api.sta.montblanc.biz/api/v1.0',
  environmentName: 'QA',
  loglevel: LogSeverity.Warning,
  defaultLanguage: 'en-US',
  supportedLanguages: ['en-US', 'de-DE'],
  loginExpiryTime: 30,
  mbBaseOAuthConfig:  new MbBaseOAuthConfig({
    clientId: 'e75ffef8-c7a7-4123-9a8a-49d1df41e836',
    authority: 'https://richemontlabb2c.b2clogin.com/richemontlabb2c.onmicrosoft.com/b2c_1a_ric-signuporsignin', // Tenant
    scopes: ['https://richemontlabb2c.onmicrosoft.com/mtb-wp/access'],
    scopeEndpoint: 'https://welcomepage-api.sta.montblanc.biz',
    knownAuthorities: ['https://richemontlabb2c.b2clogin.com'],
    logLevel: 'Error',
    isB2C: true,
    cacheLocation: MbOAuthBrowserCacheLocation.LocalStorage
  })
};
